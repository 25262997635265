<template>
  <div class="competition">
    <div class="swiper-container" id="makeBanner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
          <el-image
            :src="item.fileUrl"
            :alt="item.fileName"
            fit="cover"
          ></el-image>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
    </div>
    <div class="news">
      <div class="content">
        <div class="c-title anime">
          赛事动态
          <span></span>
        </div>
        <div class="list">
          <a :href="`/competition/newsDetail?id=${item.id}`" class="item anime" v-for="item in datalist" :key="item.id">
            <el-image :src="item.firstImage" fit="cover" lazy></el-image>
            <div class="info">
              <h3>{{ item.title }}</h3>
              <p>{{item.remarks}}</p>
              <span>了解更多 >></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/assets/swiper/js/swiper.min.js";
import "@/assets/swiper/css/swiper.min.css";
export default {
  data() {
    return {
      bannerList: [],
      datalist: [],
      awardList: [
        { id: 1, title: "最佳优秀设计奖", num: "1项", content: "3000RMB/项" },
        { id: 2, title: "金奖", num: "8项", content: "2000RMB/项" },
        { id: 3, title: "银奖", num: "10项", content: "1000RMB/项" },
        { id: 4, title: "优胜奖", num: "20项", content: "证书+奖杯" },
        { id: 5, title: "最佳指导导师奖", num: "5项", content: "证书+奖杯" },
        { id: 6, title: "最佳组织奖", num: "1项", content: "证书+奖杯" }
      ]
    };
  },
  mounted() {
    this.$api.get("/api/v1/getYzVoteDefaultSetting").then((res) => {
      if (res) {
        if (window.innerWidth < 1000) {
          this.bannerList =
            res.data.listindex_mobile_banner_image ||
            res.data.listindex_pc_banner_image;
        } else {
          this.bannerList = res.data.listindex_pc_banner_image;
        }
        new Swiper("#makeBanner", {
          autoplay: {
            delay: 3500,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          },
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagination1",
            clickable: true,
          },
        });
      }
    });
    this.$api
      .post(
        "/api/v1/getYzVoteDynamicList",
        this.$common.getFormData({
          pageNo: 1,
          pageSize: 100
        })
      )
      .then(res => {
        this.datalist = res.data.list.map(item => {
          item.firstImage = item.firstImage.replace(
            "https://static.huixianggongye.com",

            window.config.sapi
          );
          return item;
        });
        this.$nextTick(() => {
          this.$store.commit("anime");
        });
      });
    this.$parent.finish ? this.$parent.finish() : null;
  }
};
</script>
<style lang="scss" scoped>
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 4vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
    }
  }
  .news {
    margin-top: 5vw;
    .list {
      .item {
        margin-top: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-image {
          width: 660px;
          height: 370px;
        }
        .info {
          width: calc(100% - 680px);
          margin-left: 20px;
          color: #fefeff;
          h3 {
            font-size: 24px;
            @include lines();
            height: 80px;
            line-height: 40px;
          }
          p {
            margin-top: 60px;
            font-size: 18px;
          }
          span {
            display: block;
            margin-top: 170px;
            font-size: 18px;
            color: $c;
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .competition {
    color: white;
    .c-title {
      text-align: center;
      font-size: 5vw;
      color: #fffefe;
      span {
        width: 12vw;
        height: 1vw;
        display: block;
        background-color: $c;
        box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
        margin: 3vw auto 0;
      }
    }
    .text-item {
      margin-top: 5vw;
      .content {
        .desc {
          margin-top: 4vw;
          font-size: 3.5vw;
          line-height: 6vw;
          letter-spacing: 2px;
          &.c {
            text-align: center;
          }
        }
      }
    }
    .news {
      margin-top: 5vw;
      .list {
        .item {
          margin-top: 4vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .el-image {
            width: 40vw;
            height: 25vw;
          }
          .info {
            width: calc(100% - 42vw);
            margin-left: 2vw;
            color: #fefeff;
            h3 {
              font-size: 4vw;
              @include lines();
              height: 12vw;
              line-height: 6vw;
            }
            p {
              margin-top: 2vw;
              font-size: 3.5vw;
            }
            span {
              display: block;
              margin-top: 2vw;
              font-size: 3.5vw;
              color: $c;
            }
          }
        }
      }
    }
  }
}
</style>